<template>
  <span class="medic-tag ">
    <span class="" v-if="productItem.toxicityLevel">
      <!-- 有毒 -->
      <img v-if="productItem.toxicityLevel == 1" width="30" src="@/assets/medicine/tag_youdu@2x.png">
      <!-- 大毒 -->
      <img v-if="productItem.toxicityLevel == 2" width="30" src="@/assets/medicine/tag_dadu@2x.png">
      <!-- 小毒 -->
      <img v-if="productItem.toxicityLevel == 3" width="30" src="@/assets/medicine/tag_xiaodu@2x.png">
    </span>
    <!-- RX -->
    <img v-if="productItem.otc == 0" width="22" src="@/assets/medicine/tag_RX@2x.png">
    <!-- OTC甲 -->
    <img v-if="productItem.otc == 2" width="40" src="@/assets/medicine/tag_otc_jia@2x.png">
    <!-- OTC乙 -->
    <img v-if="productItem.otc == 3" width="40" src="@/assets/medicine/tag_otc_yi@2x.png">
    <span class="" v-if="productItem.label">
      <!-- 基药 -->
      <img v-if="isLabel(productItem.label,1)" width="30" src="@/assets/medicine/tag_jiyao@2x.png">
      <!-- 麻 -->
      <img v-if="isLabel(productItem.label,2)" width="20" src="@/assets/medicine/tag_ma@2x.png">
      <!-- 精神 -->
      <img v-if="isLabel(productItem.label,3)" width="20" src="@/assets/medicine/tag_jingshen_icon@2x.png">
      <!-- 运动员禁用 -->
      <img v-if="isLabel(productItem.label,4)" width="58" src="@/assets/medicine/tag_jinyong@2x.png">
    </span>
    <!-- 热 -->
    <img v-if="productItem.clhot == 399" width="16" src="@/assets/medicine/hot_biaoqian@2x.png">
  </span>
</template>

<script>
export default {
  props: {
    productItem:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    isLabel(label, index) {
      return label.split(',').includes(String(index))
    }
  }
}
</script>

<style lang="scss" scope>
.medic-tag{
  font-size: 0;
  img{
    margin-right: 6px;
    vertical-align: middle;
  }
}
</style>