import { api } from '@/utils/fetch'

/** 有毒药品热搜索词列表 */
const poisonDrugSearchWordList = params => api('drug/poisonDrugSearchWordList', params)

/** 搜索有毒药品列表 */
const poisonDrugSearch = params => api('drug/poisonDrugSearch', params)

/** 搜索有毒中草药列表 */
const herbalDrugSearch = params => api('drug/herbalDrugSearch', params)

/** 搜索有毒中成药列表 */
const patentDrugSearch = params => api('drug/patentDrugSearch', params)

/** 查询中草药详情数据 */
const herbalDrugInfo = params => api('drug/herbalDrugInfo', params)

/** 查询中成药详情数据 */
const patentDrugInfo = params => api('drug/patentDrugInfo', params)

/** 有毒药品收藏列表 */
const poisonDrugCollectList = params => api('drug/poisonDrugCollectList', params)

/** 有毒药品收藏/取消收藏 */
const poisonDrugCollect = params => api('drug/poisonDrugCollect', params)


export {
  poisonDrugSearchWordList,
  poisonDrugSearch,
  herbalDrugSearch,
  patentDrugSearch,
  herbalDrugInfo,
  patentDrugInfo,
  poisonDrugCollectList,
  poisonDrugCollect
}
