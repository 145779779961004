
// 新接口
import axios from 'axios'
import Encode from './encode'
import { getCookie, createUuid } from '@/utils/common'
import { Message } from 'element-ui'

export const api = function (url, body, platform, method, buriedPointReset) {
  const data = {
    'brand': '',
    'guid': getCookie('uuid') ? getCookie('uuid') : createUuid(), // 必传
    'model': '',
    'platform': platform || 'PC',
    'sysVersion': '',
    'time': parseInt(+new Date() / 1000),
    'uid': getCookie('uid') ? getCookie('uid') : 0, // 必传
    'version': '4.8.0',
    'session': getCookie('session') ? getCookie('session') : '',
    'body': body
  }
  if (buriedPointReset) {
    data.buriedPointReset = buriedPointReset
  }
  // console.log(`%c 请求：${url}`, 'color: blue;font-size: 16px', data)
  console.log(`%c 请求：${url}`, 'color: blue;font-size: 16px', body)
  let sendData = Encode.encodeFunc(data)
  return new Promise((resolve, reject) => {
    axios({
      method: method || 'post',
      url: process.env.VUE_APP_URL + url,
      data: sendData
    })
      .then((res) => {
        // console.log(res);
        let getData = res.data
        let decodeGetData = JSON.parse(Encode.decodeFuc(getData.data)) // 解密data   获取的是字符串
        // let toAes = Encode.encodeFunc(decodeGetData) // 对解密的数据加密
        console.log(`%c 响应：${url}`, 'color: green;font-size:16px;', decodeGetData)
        if (decodeGetData.code === 200) {
          resolve(decodeGetData.body)
        } else {
          const timeError = {
            msg: decodeGetData.msg,
            data: decodeGetData,
            code: decodeGetData.code
          }
          // Message({
          //   message: timeError.msg,
          //   type: 'error',
          //   duration: 5 * 1000
          // })
          reject(timeError)
        }
      })
      .catch((err) => {
        console.log(err)
        let error = {
          msg: '服务器错误'
        }
        console.debug(err)
        reject(error)
      })
  })
}
